(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).ready(function () {
  console.log('...');
  $('a[data-fancybox]').fancybox({
    animationEffect: "fade"
  });
  var swiper1 = new Swiper(".mySwiper", {
    slidesPerView: 3,
    direction: 'vertical',
    speed: 800,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  });
  var swiper2 = new Swiper(".products-swiper", {
    slidesPerView: 3,
    spaceBetween: 27,
    speed: 800,
    navigation: {
      nextEl: ".product-swipe-button-next",
      prevEl: ".product-swipe-button-prev"
    },
    breakpoints: {
      '0': {
        slidesPerView: 1,
        spaceBetween: 0
      },
      '768': {
        slidesPerView: 2,
        spaceBetween: 20
      },
      '991': {
        slidesPerView: 3,
        spaceBetween: 27
      }
    }
  });
  var swiper3 = new Swiper(".brands-swiper", {
    slidesPerView: 5,
    spaceBetween: 30,
    speed: 800,
    autoplay: {
      delay: 2500
      //disableOnInteraction: false,
    },

    breakpoints: {
      '0': {
        slidesPerView: 1,
        spaceBetween: 0
      },
      '768': {
        slidesPerView: 3,
        spaceBetween: 10
      },
      '991': {
        slidesPerView: 5,
        spaceBetween: 30
      }
    }
  });
  $('.mobile-menu-title').click(function () {
    $('.main-panel-group').slideToggle();
  });

  //cokie
  var _cookiePolicy = Cookies.get("cookiePolicy");
  if ($(".cookie-policy").length > 0 && _cookiePolicy != "true") {
    $('.cookie-policy').addClass('active');
  }
  $('.cookie-policy .cookie-close').click(function (e) {
    e.preventDefault();
    $(this).parents('.cookie-policy').removeClass('active');
    Cookies.set("cookiePolicy", "true", {
      expires: 999
    });
  });
  var _popup = Cookies.get("popup");
  if (_popup != "true") {
    $.fancybox.open({
      src: 'assets/images/content/new-year-tr.jpeg',
      afterClose: function afterClose() {
        Cookies.set("popup", "true", {
          expires: 999
        });
        console.log('close');
      }
    });
  }
  $(".announcements .panel-group .panel-heading a").click(function () {
    $(this).parents(".panel-group").find(".panel-default").each(function (index, el) {
      $(this).removeClass('active');
    });
    $(this).parents(".panel-default").addClass('active');
    var aa = $(this).parents('.panel-default').find('.panel-collapse');
    if (aa.hasClass('show')) {
      $(this).parents('.panel-default').removeClass('active');
    }
  });

  //form
  $('.js-letters_only').keyup(function () {
    this.value = this.value.replace(/[^a-zA-ZıİşŞÜüğĞÇçöÖ ]/g, '');
  });
  $.validator.addMethod("validate_email", function (value, element) {
    if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  });
  $.validator.addMethod("lettersonly", function (value, element) {
    return this.optional(element) || /^[a-zA-Z ıİşŞÜüğĞÇçöÖ]+$/i.test(value);
  });
  $('.js_phone_mask').mask('500 000 00 00');
  $("#contactForm").validate({
    ignore: [],
    errorClass: "error",
    errorPlacement: function errorPlacement(error, element) {
      var elementName = element[0].className;
      //console.log(elementName);
      if (elementName == 'parent-error error') {
        $(element).parent(".form-item").addClass('error-validation');
        error.insertAfter($(element)); //error-msg add.
      } else {
        error.insertAfter($(element)); //error-msg add.
      }
    },

    rules: {
      name: {
        required: true,
        lettersonly: true,
        minlength: 3
      },
      email: {
        required: true,
        validate_email: true
      },
      phone: {
        required: true,
        minlength: 13
      },
      message: {
        required: true,
        minlength: 4
      }
      /*kvkk : {
      	required: true
      }*/
    },

    /*unhighlight: function (element, errorClass, validClass) {
    	if ($(element).is('select')) {
    		//error.insertAfter($(element).next());
    		$(element).parents('.form-item').removeClass('error-validation');
    	}
    },*/
    submitHandler: function submitHandler(form) {
      // do other things for a valid form
      //console.log('form submit');
      formSend(form);
      console.log('form submit');
    },
    invalidHandler: function invalidHandler(form, validator) {
      /*$('html, body').animate({
      	scrollTop: $(validator.errorList[0].element).offset().top-$('header').innerHeight() - 30
      }, 1000);*/
    }
  });
});
function formSend(form) {
  var action = $(form).attr("action"),
    method = $(form).attr("method"),
    name = $(form).attr('name'),
    veri = $(form).serialize(),
    validate = $(form);

  // console.log(veri);
  $.ajax({
    type: method,
    url: action,
    data: veri,
    cache: false,
    success: function success(response) {
      //response = 1;
      if (response == 1) {
        $('#contactForm').find('p').addClass('success');
        $('#contactForm').find('p').html($('#contactForm').find('p').data('success'));
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } else {
        $('#contactForm').find('p').addClass('error');
        $('#contactForm').find('p').html($('#contactForm').find('p').data('error'));
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
    }
  });
}

},{}]},{},[1]);
